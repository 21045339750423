import React, { Component } from "react"
import Layout from "../components/layout"
import FaqContentThree from "../components/Faq/FaqContentThree"
import FreeTrialArea from "../components/Home/FreeTrialArea"
import HubSpotForm from "../components/HubSpotForm"

class Revit extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <HubSpotForm />
        </Layout>
      </React.Fragment>
    )
  }
}

export default Revit
